import React, { useEffect,useRef,useState } from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import ReactLoading from 'react-loading';
import Moment from 'react-moment';
import '../../components/loader.css';
import TablePagination from '@mui/material/TablePagination';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import { useNavigate } from 'react-router-dom'
import download from 'downloadjs'
import { DateRangePicker } from 'react-date-range';
import { addDays } from "date-fns";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';




const Shipments = () => {
    let navigate = useNavigate();
    const [state, setState] = React.useState({
        shipmnent_list: [],
        dataCount: 0,
        isLoading: true,
        dialogOpen: false,
        searchValue: "",
       

    })
    const inputRef = useRef(null);
    const search = window.location.search;
    // console.log("search -   ",search);
    const params = new URLSearchParams(search);
    let title= params.get('title')
    let juu = search.split("?status=");
    // console.log("params 00  -- - -  - - -  ",juu)
    let shipmetFilterUrl = [];
    if(juu[1]) {
    // console.log("params  -- - -  - - -  ",juu[1].split('%26'))
     shipmetFilterUrl = juu[1].split('%26');
    }
    //let status = params.get('status');
    //console.log("status====",status)
   

  const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
const[modals,setModals] = React.useState({show: false,invoice_id:""})
const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
const [statusfilters, setstatusFilters] = React.useState({ current_status: shipmetFilterUrl })
const [searchfilters, setsearchFilters] = React.useState({ searchtitle: "",filter:[{name:"booked",value:1,status:true},{name:"assigned",value:2,status:true},{name:"intransit",value:3,status:true},{name:"delivered",value:4,status:true}],filterStatus:[]})
console.log("filter_status ==" ,searchfilters )
// const [searchfilters, setsearchFilters] = React.useState({ filterStatus:[]})
const[dimensionState,setdimensionState] = React.useState([])
const [isLoading, setIsLoading] = useState(false);
const [setDate, setDateState] = useState({date:""});
const [focusedIndex, setfocusedIndex] = React.useState(-1);
const [searchwithstate, setsearchwithstate] = React.useState({search_with:"order"});
const [searchshipment , setsearchshipment] = React.useState({shipment_id:""})
const [datestate, setdateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
      isOpen: false,
    },
  ]);
  var months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var days = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  const xcc =
    datestate[0].startDate.getFullYear() +
    "-" +
    months[datestate[0].startDate.getMonth()] +
    "-" +
    days[datestate[0].startDate.getDate()];
  const ycc =
  datestate[0].endDate.getFullYear() +
    "-" +
    months[datestate[0].endDate.getMonth()] +
    "-" +
    days[datestate[0].endDate.getDate()];
const [rstate, setRState] = useState({
    suggestions: [],
    text: "",
  });
  const[customeridstate , setcustomeridstate]= React.useState({customer_id:""})
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
// const dimension_detail = () =>{
//     let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
//     let sendData = {};
  
//     axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
//       setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
//       console.log("rnmmmmm", res);
//     }).catch((e) => {
//       // toast.configure()
//        //toast.error("Some thing went wrong")
//       console.log("----error:   ", e);
//     })
//   }
  useEffect(() => {   
 //   axios_get_api() 
 customerProfile()
   
  }, [])

  

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    console.log("urlSearchParams == " , urlSearchParams)
    // Get and set the customer_id from the URL
    const urlCustomerID = urlSearchParams.get('customer_id');
    const urlStatus = urlSearchParams.get('status');
    console.log("urlStatus == " , urlStatus)
    const decodedStatus = decodeURIComponent(urlStatus);
    const statusArray = decodedStatus.split(',');
    const statusNumbers = statusArray.map(Number);
    console.log("statusNumbers == " , statusNumbers)
    const order_number = urlSearchParams.get('number');
   
    if (urlCustomerID && !urlStatus && !order_number) {
      
          console.log('Customer ID:', urlCustomerID);
          let full_api1 = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_id_profile`;
          let sendData1 = {customer_id:urlCustomerID};
        
          axios.post(full_api1, sendData1, { headers: exportValue.headers }).then(res => {
            // setdimensionState(res.data.output);
            setRState({...rstate , text:res.data.output[0].full_name })
            setcustomeridstate({...customeridstate , customer_id:res.data.output[0].customer_id})
            console.log("rnmmmmm", res);
          }).catch((e) => {
            // toast.configure()
             //toast.error("Some thing went wrong")
            console.log("----error:   ", e);
          })
        //   console.log("onLoad--->",onLoad)
        let onLoad = true
        let index = 0 
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:urlCustomerID,
            date: ""
        };
        console.log("sendData",sendData)
      
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
            if(index==0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount ,  page:index})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(!urlCustomerID && !order_number &&urlStatus && statusNumbers.length>0){
        let onLoad = true
        let index = 0 
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:"",
            date: ""
        };
        console.log("sendData",sendData)
      
            sendData.status= statusNumbers
        
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
            if(index==0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount ,  page:index})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(order_number && !urlCustomerID && !urlStatus ){
        setsearchshipment({shipment_id:order_number})
        let onLoad = true
        let index = 0 
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:"",
            date: "",
            search_with:searchwithstate.search_with
        };
        console.log("sendData",sendData)
           sendData.find= order_number
      
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
            if(index==0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount ,  page:index})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(urlCustomerID &&urlStatus && statusNumbers.length>0 && !order_number){
        console.log('Customer ID:', urlCustomerID);
        let full_api1 = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_id_profile`;
        let sendData1 = {customer_id:urlCustomerID};
      
        axios.post(full_api1, sendData1, { headers: exportValue.headers }).then(res => {
          // setdimensionState(res.data.output);
          setRState({...rstate , text:res.data.output[0].full_name })
          setcustomeridstate({...customeridstate , customer_id:res.data.output[0].customer_id})
          console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
        let onLoad = true
        let index = 0 
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:urlCustomerID,
            date: ""
        };
        console.log("sendData",sendData)
      
        if(searchfilters.searchtitle != ""){
            sendData.find= searchfilters.searchtitle
        }
        // if(searchfilters.filterStatus.length > 0) {
            sendData.status= statusNumbers
        // }
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
            if(index==0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount ,  page:index})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(urlCustomerID && !urlStatus && order_number){
        console.log('Customer ID:', urlCustomerID);
        let full_api1 = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_id_profile`;
        let sendData1 = {customer_id:urlCustomerID};
      
        axios.post(full_api1, sendData1, { headers: exportValue.headers }).then(res => {
          // setdimensionState(res.data.output);
          setRState({...rstate , text:res.data.output[0].full_name })
          setcustomeridstate({...customeridstate , customer_id:res.data.output[0].customer_id})
          console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
        let onLoad = true
        let index = 0 
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:urlCustomerID,
            date: "",
            search_with:searchwithstate.search_with,

        };
        console.log("sendData",sendData)
         sendData.find= order_number
        
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
            if(index==0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount ,  page:index})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(!urlCustomerID && urlStatus && statusNumbers.length>0 && order_number){
        let onLoad = true
        let index = 0 
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:"",
            date: "",
            search_with:searchwithstate.search_with,

        };
        console.log("sendData",sendData)
         sendData.find= order_number
         sendData.status = statusNumbers
        
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
            if(index==0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount ,  page:index})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(urlCustomerID && urlStatus && statusNumbers.length>0 && order_number){
        console.log('Customer ID:', urlCustomerID);
        let full_api1 = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_id_profile`;
        let sendData1 = {customer_id:urlCustomerID};
      
        axios.post(full_api1, sendData1, { headers: exportValue.headers }).then(res => {
          // setdimensionState(res.data.output);
          setRState({...rstate , text:res.data.output[0].full_name })
          setcustomeridstate({...customeridstate , customer_id:res.data.output[0].customer_id})
          console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
        let onLoad = true
        let index = 0 
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:urlCustomerID,
            date: "",
            search_with:searchwithstate.search_with,

        };
        console.log("sendData",sendData)
         sendData.find= order_number
         sendData.status = statusNumbers
        
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
            if(index==0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount ,  page:index})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else{
    
     axios_get_api()    }
    
   }, [otherStates.rowsPerPage,searchfilters.filterStatus,customeridstate.customer_id, setDate.date])

//    useEffect(()=>{
   
       
    
  
//     // Get and set the status array from the URL
//     const urlStatus = urlSearchParams.get('status');
//     if (urlStatus) {
//       // Split the comma-separated values into an array
//       const statusArray = urlStatus.split(',').map(Number); // Convert values to numbers
//       setsearchFilters({...searchfilters,filterStatus:statusArray});
//     }
  
//    })


    const axios_get_api = (index=0,onLoad = true) => {
        //  console.log("dstatus--->",shipment_status)
         console.log("onLoad--->",onLoad)
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:customeridstate.customer_id,
            date: setDate.date,
            search_with:searchwithstate.search_with,
            start_date:xcc,
            to_date:ycc
        };
        console.log("sendData",sendData)
            sendData.find= searchshipment.shipment_id
        if(searchfilters.filterStatus.length > 0) {
            sendData.status= searchfilters.filterStatus
        }
       
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
            // console.log("index",index)
            // console.log("onLoad",onLoad)

            if(index==0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount ,  page:index})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
          
          console.log( "response",res.data.output);
        //      if (status != null) {
        //     console.log("loct - -   ", JSON.parse(status));
        //      setstatusFilters({...statusfilters, current_status:JSON.parse(status)})
        // }



        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })

    }
//     const searchUser = ( shipment_status,index=0) => {
//         let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_find_detail`;
            
//         let transporter_data= { indexValue:index, limit:otherStates.rowsPerPage, dstatus:1,title:"", shipment_status: shipment_status,};
//         // console.log("pv",state.title)
//         if ( searchfilters.searchtitle) {
//            transporter_data.title=  searchfilters.searchtitle;
//          }
//          console.log("transporter",transporter_data)
       
//     axios.post(full_api,transporter_data, { headers: exportValue.headers }).then(res => {        
// //    setState({ ...state,searchValue:res.data.title})
//    console.log( "response123",res);
//    if(index==0 && otherStates.onload) {
//     setOtherState({...otherStates,total_count:res.data.dataCount,onload:false})         
// }  
//    setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
   
   

//    }).catch((e) => {
//     Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: 'Something went wrong!',
        
//       })
//        console.log("----error:   ", e);
//    })          
//   }

    const stateHandler = (e) => {    
        // setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
        setsearchshipment({shipment_id:e.target.value})
        const url = new URL(window.location);
        url.searchParams.set('number', e.target.value); // You can choose the appropriate query parameter name
        window.history.pushState({}, '', url.toString());
    }

    const searchOnkeyPress = (e,val) =>{
        console.log("e=>    ",e);
        console.log("val=>    ",val);
        // if((e.charCode == 13 || val)) {
            axios_get_api()
        // }
    }


    const filter=(e,values)=>{
        const name = e.target.name;
        const value = values;
        const isChecked = e.target.checked;
      
        // Create a new array by spreading the existing filterStatus array
        const updatedFilterStatus = [...searchfilters.filterStatus];
      
        if (isChecked) {
          updatedFilterStatus.push(value);
        } else {
          // Remove the value from the array if unchecked
          const index = updatedFilterStatus.indexOf(value);
          if (index !== -1) {
            updatedFilterStatus.splice(index, 1);
          }
        }
      
        // Update the searchfilters state with the updated filterStatus array
        setsearchFilters((prevFilters) => ({
          ...prevFilters,
          filterStatus: updatedFilterStatus,
        }));
        
        const selectedStatus = updatedFilterStatus;
        const statusQueryString = `status=${selectedStatus.join(',')}`;
        
        // Get the existing query parameters
        const url = new URL(window.location);
        const searchParams = new URLSearchParams(url.search);
        
        // Set the new status parameter
        searchParams.set('status', selectedStatus.join(','));
        
        // Update the URL with the modified query parameters
        url.search = searchParams.toString();
        window.history.pushState({}, '', url.toString());
      }
      
React.useEffect(()=>{
    if (title) {
       // setsearchFilters({...searchfilters,searchtitle:title})
    }
//searchUser(null,0)
// axios_get_api(null,0,true);


},[])
// React.useEffect(()=>{
//     if (statusfilters.current_status.length > 0) {   
//         params.delete('status');
//         // console.log("current_status ===========>    ",statusfilters.current_status); 
//         // console.log("current_status ===========>    ",statusfilters.current_status.join('&'));        
//         params.append('status', statusfilters.current_status.join('&'))
      
//     }
//     else{
        
         
//     }
    
//    // axios_get_api(null,0,true);

//     navigate({ search: params.toString() })
  
// },[statusfilters])

    
    // const shipmentFilter = (dstatus) => {
    //      console.log("action ",dstatus);
    //     setshipStatus({ ...shipmentstate, shipment_status: dstatus })
      
    //     axios_get_api(dstatus,0,true)
        
    //   }
   
    function deleteship(invoice_id) {
        let userdata = {invoice_id:invoice_id}
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_remove` , userdata ,{ headers: exportValue.headers }).then((res)=>{
       
         setState({...state,invoice_id:invoice_id});
         setModals({...modals, show:false})
         console.log("res",res)
         axios_get_api();
         if(res.data.status==200){
            Swal.fire(
                'Good job!',
                'Shipment is Deleted Successfully !',
                'success'
              )
         }
         else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
         }
         }).catch((e) => {
   
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
           
         })
     }
  
     const handlePageChange = (event,newPage) =>{
        // console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        axios_get_api(newPage,true);
        
       // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
     console.log("event ", event.target.value);
     console.log("newPage ", newPage);
       setOtherState({...otherStates, rowsPerPage:event.target.value, page:0})
       
       
    }
  
  
   

   

     const status_update = (shipment_id)=>{

     let full_api = "https://skyshipbackend.fniix.com/v.1.0.1/web/query/shipment/shipment_tracking"
     let sendData= {trackingNumber:shipment_id};
        console.log("senddata",sendData)
       
     axios.post(full_api,sendData, { headers: exportValue.headers }).then(res => {        
      
        console.log("res",res)
        if(res.data.message=="success"){
           
            Swal.fire(
                'Success!',
                ' Status Updated Successfully !',
                'success'
              )
              window.location.reload(false);
        }

        
        else{
            Swal.fire(
                'Unsuccess!',
                'Something Went Wrong !',
                'success'
              )
              window.location.reload(false);
       
        }
    })

     }

     const shipping_label =(sub)=>{
        console.log("sub ==== " , sub)
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://dtdcapi.shipsy.io/api/customer/integration/consignment/shippinglabel/stream?reference_number='+sub.carrier_tracking_number,
          responseType: 'blob',
          headers: { 
            'Content-Type': 'application/pdf', 
            'api-key': 'e96fb203eef06314303c1aa7ef8c0c'
          }
        };
        
        axios.request(config)
        .then((response) => {
          console.log((response.data));
          download(response.data, 'dtdc.pdf')
          
        })
        .catch((error) => {
          console.log(error);
        });
      
        
       }

   const customerProfile =()=>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_profile`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState(res.data.output);
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
 }

       const customerHandle =(e)=>{
        const value = e.target.value;
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`^${value}`, "i");
          dimensionState.map((sub)=>{
            if(sub.email.match(regex)!=null){
                suggestions.push(sub)
            }
        })
        //   suggestions = dimensionState.sort().filter((full_name) => regex.test(full_name));
          console.log("suggestions ==" , suggestions)
  
        }
        setRState(() => ({
          suggestions,
          text: value,
        }));
       }
       
       function selectedText(value) {

          setRState(() => ({
            text: value.full_name,
            suggestions: [],
          }));
          setcustomeridstate({...customeridstate, customer_id:value.customer_id})
          const url = new URL(window.location);
  url.searchParams.set('customer_id', value.customer_id); // You can choose the appropriate query parameter name
  window.history.pushState({}, '', url.toString());
        
      }
  
      // const selectDate=(e)=>{
      //   setDateState({...setDate , date:e.target.value})
      // }
      const handleKeyDown = (e) => {
        console.log("e == " , e.key)
        if (e.key == 'ArrowDown') {
          e.preventDefault();
          if (rstate.suggestions.length > 0) {
            console.log("suggestions length === " , rstate.suggestions.length)
            let selectedItem = 0
             selectedItem =  (focusedIndex + 1) % rstate.suggestions.length
            console.log("selectedItem == " , selectedItem)
            setfocusedIndex(selectedItem)
          }
        }
         else if (e.key === 'ArrowUp') {
          e.preventDefault();
          if (rstate.suggestions.length > 0) {
            let selectedItemIndex = 0
             selectedItemIndex = (focusedIndex - 1 + rstate.suggestions.length) % rstate.suggestions.length;
           setfocusedIndex(selectedItemIndex)
          }
        } else if (e.key === 'Enter') {
          if (focusedIndex !== -1) {
            // Handle Enter key press, e.g., select the item, and clear the input field
            const selectedItem = rstate.suggestions[focusedIndex];
            console.log("selectedItem ==" , selectedItem)
            selectedText(selectedItem);
           
          }
        }
      };
      const selectsearchwith =(e)=>{
        setsearchwithstate({search_with:e.target.value})
      }

      function copyToClipboard(value) {
        navigator.clipboard.writeText(value)
          .then(() => {
            console.log('Text copied to clipboard:', value);
            // Optionally, you can provide feedback to the user here
          })
          .catch((error) => {
            console.error('Failed to copy text to clipboard:', error);
            // Handle errors here
          });
      }
      
  return (
    <div>
        {/* <Sub_header/> */}
        {/* <div style={{marginLeft:"15px",marginRight:"15px"}}>
         <section class="mt-3">
         <div class="row mb-3">
            <div class="col-12 col-md-2">
                <h5>All Shipments</h5>
            </div>
            <div class="col-12 col-md">
                <div class="row">
                    <div class="col-12 col-md">
                        <div class="input-group me-2 me-lg-3 fmxw-400">
                            <select class="form-select" name="search_with" onChange={(e)=>selectsearchwith(e)}>
                              <option value="order">Order #</option>
                              <option value="awb">AWB #</option>
                              <option value="shipment">Shipment #</option>
                            </select>
                            <input type="text" class="form-control" placeholder="Search Shipments" onChange={(e)=>stateHandler(e)} onKeyPress={(event) => searchOnkeyPress(event)} value={searchshipment.shipment_id}/>
                            <span class="input-group-text" onClick={(event) => searchOnkeyPress(event,"click")}>
                                <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                          <section className=''> <input className='form-control' name="customer_name" placeholder='Enter Customer Name' onChange={(e)=>customerHandle(e)} value={rstate.text} autoComplete='off' onKeyDown={(e) => handleKeyDown(e)}  ref={inputRef}/>
            <ul>
            {rstate.suggestions.map((item, index) => (
            <li 
            className="form-control"
            value={rstate.text}
           
            key={index}
          onClick={() => selectedText(item)}
         
          style={{
            listStyle: 'none',
            backgroundColor: index === focusedIndex ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.05)",
            border: "1px solid #CCC",
            boxShadow: "inset 1px 1px 0 rgba(255, 255, 255, 0.5)",
            cursor: "pointer",
            borderRadius: "3px",
            padding: "10px 15px 10px 25px",
           
          }}>
                {item.full_name} , {item.email}
            </li>
            ))}
          </ul></section>
                      
                    </div>
                    <div class="col-12 col-md-2">
                 
                          <div
                           class="modal modal fade bd-example-modal-lg"
                           id="bd-example-modal-lg" 
                           tabindex="-1"
                           role="document"
                           aria-labelledby="myLargeModalLabel"
                           aria-hidden="true"
                          >
                            <div class="modal-dialog modal-lg" role="document">
                              <div class="modal-content">
                                <div class="modal-body">
                                  <DateRangePicker
                                    onChange={(item) =>
                                        setdateState([item.selection])
                                    }
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={datestate}
                                    showPreview={false}
                                    direction="horizontal"
                                  />
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                    <div class="col-12 col-md-2 text-end">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-filter" aria-hidden="true"></i> &nbsp;
                                Filter <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </button>
                            <div class="dropdown-menu">
                                <section class="p-2">
                                <h6>Filter</h6>
                                <hr/>
                            <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_awaited" value="something"  onChange={(e)=>filter(e,1)}/>
                               <label class="form-check-label">Pickup Awaited</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_scheduled" value="something" onChange={(e)=>filter(e,9)}/>
                               <label class="form-check-label">Pickup Scheduled</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="picked_up" value="something"  onChange={(e)=>filter(e,2)}/>
                               <label class="form-check-label">Picked Up</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="in_transit" value="something"  onChange={(e)=>filter(e,3)}/>
                               <label class="form-check-label">In Transit</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="out_for_delivery" value="something"  onChange={(e)=>filter(e,4)}/>
                               <label class="form-check-label">Out for Delivery</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="delivered" value="something"  onChange={(e)=>filter(e,5)}/>
                               <label class="form-check-label">Delivered</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="cancelled" value="something"  onChange={(e)=>filter(e,6)}/>
                               <label class="form-check-label">Cancelled</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="failed" value="something"  onChange={(e)=>filter(e,7)}/>
                               <label class="form-check-label">Failed</label>
                           </div>
                                </section>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
     
        </div>
        {(isLoading) ?
                <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> : 
            
                 <div class="card card-body border-0 shadow table-wrapper table-responsive mt-3">
                 
                
                <table class="table table-hover table-bordered shipm_tablew">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Order Id #</th>	
                            <th class="border-gray-200">Origin</th>
                            <th class="border-gray-200">Destination</th>	
                            <th class="border-gray-200 ship_pickup">Sender</th>
                            <th class="border-gray-200">Receiver</th>
                            <th class="border-gray-200">Amount</th>
                            <th class="border-gray-200">Carrier</th>
                            <th class="border-gray-200">Created By</th>
                            <th class="border-gray-200">Status</th>
                            <th class="border-gray-200">Action</th>
                        </tr>
                    </thead>
                   
                    <tbody>
                      {state.shipmnent_list.map((item)=>
                        <tr>
                            <td><a href={"/shipment_detail/"+item.shipment_id} class="text-info fw-700 fs-6">{item.carrier_tracking_number?item.carrier_tracking_number:""}</a>
                                <br/><small><a href={"/shipment_detail/"+item.shipment_id} class="">{item.order_number}</a></small> <br/>
                                <span class="fw-normal"><Moment format="MMM DD YYYY hh:mm">
                        {new Date(item.created*1000)}
            </Moment> 
           
            </span>
                            </td>                        
                            <td>
                            {item.sender[0].city}    <br/>
                            <small>{item.sender[0].country}</small>
                            </td>                        
                            <td>
                            {item.receiver[0].city}    <br/>
                            <small>{item.receiver[0].country}</small>
                            </td>
                            <td class="">
                                <strong>{item.sender[0].name}</strong>
                            </td>
                            <td>
                               <strong>{item.receiver[0].name}</strong>
                            </td>
                            <td class="fw-700 text-danger">
                            ₹{item.total_amount}
                            </td>
                            <td>
                                <img src={`https://cdn.shreebanshidharlogistics.com/images/${item.carrier_image}`} alt="" class="img-fluid"/>
                            </td>
                            <td>{item.created_by?item.created_by:""}</td>
                         
                            {item.status==0?<span class="badge rounded-pill bg-warning fw-400">Pending</span>:item.status==10?<span class="badge rounded-pill bg-danger fw-400">Not Picked</span>:item.status==1?<span class="badge rounded-pill bg-success fw-400">Pick Up Awaited</span>:item.status==2?<span class="badge rounded-pill bg-warning fw-400">Picked Up</span>:item.status==3?<span class="badge rounded-pill bg-danger fw-400">In Transit</span>:item.status==4?<span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span>:item.status==5?<span class="badge rounded-pill bg-success fw-400">Delivered</span>:item.status==6?<span class="badge rounded-pill bg-danger fw-400">Cancelled</span>:item.status==7?<span class="badge rounded-pill bg-secondary fw-400">Failed</span>:item.status==8?<span class="badge rounded-pill bg-warning fw-400">NDR</span>:item.status==9?<><span class="badge rounded-pill bg-danger fw-400">Pickup Scheduled</span><br/><br/>
                            {item.pickup_date!=undefined && item.pickup_date!=""?  <small>on {item.pickup_date!=undefined?item.pickup_date:""} at {item.pickup_time!=undefined?item.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}
                            <td>
                              
                               {item.label_status==0?
                       <a href={"/print_label/"+item.shipment_id}  target="_blank" class="btn btn-outline-danger btn-sm  mb-2 text-danger"  id="fourth-app-title"><i class="material-icons-outlined">qr_code</i></a>:item.status==7?"":item.shipping_label!=undefined && item.shipping_label!=""? <a href={item.shipping_label} target="_blank" class="btn btn-outline-danger btn-sm  mb-2 text-danger"  id="fourth-app-title"><i class="material-icons-outlined">qr_code</i></a>:<a onClick={()=>{shipping_label(item)}}  class="btn btn-outline-danger btn-sm  mb-2 text-danger"  id="fourth-app-title"><i class="material-icons-outlined">qr_code</i></a>}

                               <a href={"/print_receipts/"+item.shipment_id} target="_blank"><button type="button" class="btn btn-outline-info  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Print Receipt">receipt</i></button></a>

                               <a href={"/print_invoice/"+item.shipment_id} target="_blank"> <button type="button" class="btn btn-outline-success  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Invoice">article</i></button></a>
                                 
                               <a href={"https://app-skyship.fniix.com/tracking/"+item.shipment_id} target="_blank"><button type="button" class="btn btn-outline-primary  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Update Status">search</i></button></a> 
                               <a onClick={()=>status_update(item.shipment_id)} class="btn btn-outline-primary  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Update Status">refresh</i></a>
                           

                            </td>
                        </tr>
                      )}                        
                    </tbody>

                </table>
                <TablePagination
                            component="div"
                            rowsPerPageOptions={[20,25,30,35,40,45,50]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                        
                      
                        </div> 
}
               
            
                    

    </section>
    </div> */}
{/* <?php
            include('header.php');
        ?>

        <?php
            include('left_nav.php');
        ?> */}
        <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
    <section class="home-section">
            {/* <?php
            include('sub_header.php');
            ?> 
         */}
    <Sub_header/>
            
        <section class="mt-3">
        <div class="row mb-3">
            <div class="col-12 col-md-2">
                <h5>All Shipments</h5>
            </div>
            <div class="col-12 col-md">
                <div class="row">
                    <div class="col-12 col-md">
                        <div class="input-group me-2 me-lg-3 fmxw-400">
                        <select class="form-select" name="search_with" onChange={(e)=>selectsearchwith(e)}>
                              <option value="order">Order #</option>
                              <option value="awb">AWB #</option>
                              <option value="shipment">Shipment #</option>
                            </select>
                            <input type="text" class="form-control" placeholder="Search Shipments" onChange={(e)=>stateHandler(e)} onKeyPress={(event) => searchOnkeyPress(event)} value={searchshipment.shipment_id}/>
                            <span class="input-group-text" onClick={(event) => searchOnkeyPress(event,"click")}>
                                <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                    <input className='form-control' name="customer_name" placeholder='Enter Customer Name' onChange={(e)=>customerHandle(e)} value={rstate.text} autoComplete='off' onKeyDown={(e) => handleKeyDown(e)}  ref={inputRef}/>
            <ul>
            {rstate.suggestions.map((item, index) => (
            <li 
            className="form-control"
            value={rstate.text}
           
            key={index}
          onClick={() => selectedText(item)}
         
          style={{
            listStyle: 'none',
            backgroundColor: index === focusedIndex ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.05)",
            border: "1px solid #CCC",
            boxShadow: "inset 1px 1px 0 rgba(255, 255, 255, 0.5)",
            cursor: "pointer",
            borderRadius: "3px",
            padding: "10px 15px 10px 25px",
           
          }}>
                {item.full_name} , {item.email}
            </li>
            ))}
          </ul>
                    </div>
                    <div class="col-12 col-md-2">
                        {/* <input type="date" class="form-control" id="text" placeholder="select date range" /> */}
                    </div>
                    <div class="col-12 col-md-2 text-end">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-filter" aria-hidden="true"></i> &nbsp;
                                Filter <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </button>
                            <div class="dropdown-menu">
                                <section class="p-2">
                                <h6>Filter</h6>
                                <hr/>
                                <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_awaited" value="something"  onChange={(e)=>filter(e,1)}/>
                               <label class="form-check-label">Pickup Awaited</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_scheduled" value="something" onChange={(e)=>filter(e,9)}/>
                               <label class="form-check-label">Pickup Scheduled</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="picked_up" value="something"  onChange={(e)=>filter(e,2)}/>
                               <label class="form-check-label">Picked Up</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="in_transit" value="something"  onChange={(e)=>filter(e,3)}/>
                               <label class="form-check-label">In Transit</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="out_for_delivery" value="something"  onChange={(e)=>filter(e,4)}/>
                               <label class="form-check-label">Out for Delivery</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="delivered" value="something"  onChange={(e)=>filter(e,5)}/>
                               <label class="form-check-label">Delivered</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="cancelled" value="something"  onChange={(e)=>filter(e,6)}/>
                               <label class="form-check-label">Cancelled</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="failed" value="something"  onChange={(e)=>filter(e,7)}/>
                               <label class="form-check-label">Failed</label>
                           </div>
                                </section>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {(isLoading) ?
                <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> : 
            <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                <table class="table table-hover  shipm_tablew">
                    <thead>
                        <tr>
                            <th class="border-gray-200">AWB #</th>	
                            <th class="border-gray-200">Origin</th>
                            <th class="border-gray-200">Destination</th>	
                            <th class="border-gray-200 ship_pickup">Sender</th>
                            <th class="border-gray-200">Receiver</th>
                            <th class="border-gray-200">Carrier</th>
                            <th class="border-gray-200">Amount</th>
                            <th class="border-gray-200">Mode</th>

                            <th class="border-gray-200">Status</th>
                            <th class="border-gray-200">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <!-- Item --> */}
                        {state.shipmnent_list.map((item)=>
                        <tr>
                            <td>
                                <h5><a href={"/shipment_detail/"+item.shipment_id} class="text-info fw-700 fs-6">{item.carrier_tracking_number?item.carrier_tracking_number:item.shipment_id}</a>
                                    <a onClick={() => copyToClipboard(item.carrier_tracking_number ? item.carrier_tracking_number : item.shipment_id)}><i class="fa fa-clone" aria-hidden="true"></i></a>
                                </h5>
                                <h3>O ID: <a href={"/shipment_detail/"+item.shipment_id} class="text-info fw-700 fs-6">{item.order_number}</a> <a onClick={() => copyToClipboard(item.order_number)}><i class="fa fa-clone" aria-hidden="true"></i></a></h3>
                                <h6><Moment format="MMM DD YYYY hh:mm">
                        {new Date(item.created*1000)}
            </Moment> 
           </h6>
                            </td>                        
                            <td>
                            {item.sender[0].city}    <br/>
                            <small>{item.sender[0].country}</small>
                            </td>                        
                            <td>
                            {item.receiver[0].city}  <br/>
                            <small>{item.receiver[0].country}</small>  
                            </td>
                            <td class="">
                                <strong>{item.sender[0].name}</strong>
                            </td>
                            <td>
                                <strong>{item.receiver[0].name}</strong>
                            </td>
                            <td>
                                <img src={`https://cdn.shreebanshidharlogistics.com/images/${item.carrier_image}`} alt="" class="img-fluid"/>
                            </td>
                            <td class="fw-700 text-danger">
                            ₹{item.total_amount}
                            </td>
                            <td class="fw-700 text-danger">
                            {item.payment_mode}
                            </td>
                            <td>
                            {item.status==0?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Pending</span>:item.status==10?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Not Picked</span>:item.status==1?    <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Pickup Awaited</span>:item.status==2?  <span class="status pickedup"> <i class="fa fa-check" aria-hidden="true"></i> Picked Up</span>:item.status==3?  <span class="status in_transit"> <i class="fa fa-truck" aria-hidden="true"></i> In Transit</span>:item.status==4?   <span class="status out_f_deli"> <i class="fa fa-plane" aria-hidden="true"></i> Out for Delivery</span>:item.status==5?  <span class="status delivered"> <i class="fa fa-check-square-o" aria-hidden="true"></i> Delivered</span>:item.status==6?    <span class="status cancelled"> <i class="fa fa-ban" aria-hidden="true"></i> Cancelled</span>:item.status==7?    <span class="status failed"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Failed</span>:item.status==8?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> NDR</span>:item.status==9?<>   <span class="status pickup_sechdls"> <i class="fa fa-calendar-o" aria-hidden="true"></i> Pickup Scheduled</span><br/><br/>
                            {item.pickup_date!=undefined && item.pickup_date!=""?  <small>on {item.pickup_date!=undefined?item.pickup_date:""} at {item.pickup_time!=undefined?item.pickup_time:""}</small>:""} </>:  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Not Available</span>}
                                {/* <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Pickup Awaited</span> */}
                            </td>
                            <td>
                              
                              {item.label_status==0?
                      <a href={"/print_label/"+item.shipment_id}  target="_blank" class="btn btn-outline-danger btn-sm me-1 text-danger"  id="fourth-app-title"><i class="material-icons-outlined">qr_code</i></a>:item.status==7?"":item.shipping_label!=undefined && item.shipping_label!=""? <a href={item.shipping_label} target="_blank" class="btn btn-outline-danger btn-sm  mb-2 text-danger"  id="fourth-app-title"><i class="material-icons-outlined">qr_code</i></a>:<a onClick={()=>{shipping_label(item)}}  class="btn btn-outline-danger btn-sm  mb-2 text-danger"  id="fourth-app-title"><i class="material-icons-outlined">qr_code</i></a>}

                              <a href={"/print_receipts/"+item.shipment_id} className='' target="_blank"><button type="button" class="btn btn-outline-info  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Print Receipt">receipt</i></button></a>

                              <a href={"/print_invoice/"+item.shipment_id} target="_blank" className='me-1'> <button type="button" class="btn btn-outline-success  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Invoice">article</i></button></a>
                                
                              <a href={"https://app-skyship.fniix.com/tracking/"+item.shipment_id} target="_blank" className='me-1'><button type="button" class="btn btn-outline-primary  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Update Status">search</i></button></a> 
                              <a onClick={()=>status_update(item.shipment_id)} class="btn btn-outline-primary me-1 btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Update Status">refresh</i></a>
                          

                           </td>
                        </tr>
                        )}
                    </tbody>
                </table>
                <TablePagination
                            component="div"
                            rowsPerPageOptions={[20,25,30,35,40,45,50]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                {/* <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-0">
                            <li class="page-item">
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">4</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">5</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
                </div> */}
            </div> 
}
                    

    </section>
                    

    </section>


{/* <?php
include('footer.php');
?>   */}
        </div>
  )
}

export default Shipments